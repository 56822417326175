
::v-deep .el-scrollbar__thumb {
  background: rgba(18, 196, 196, 1);
  border-radius: 4px;
}
::-webkit-scrollbar-track {
  background: #040653;
  border-radius: 4px;
}
::v-deep .el-scrollbar__bar.is-vertical {
  background: #040653;
}

.visualization-subtitle-list-container {
  padding-right: 10px;
  height: 100%;
  width: 100%;
  overflow: hidden;
  background: rgba(12, 14, 63, 1);
  border: 0.01rem solid rgba(12, 14, 63, 1);
  box-shadow: 0px 0px 40px 0px rgba(12, 21, 48, 0.3);
  border-radius: 20px;
  padding-bottom: 0.1rem;
  box-sizing: border-box;
  .subtitle-list-tag {
    display: flex;
    margin: 20px 0 0 21px;

    .subtitle-list-tag-title {
      flex: 1;
      .subtitle-list-tag-img {
        margin-right: 12px;
      }
      .subtitle-list-name {
        font-size: 16px;
        color: #fff;
      }
    }
  }

  .subtitle-list-region {
    overflow-y: auto;
    height: 100%;
    width: 100%;
    margin-left: 30px;
    .scroll-content {
      height: 100%;
    }
    .subtitle-list-main {
      display: flex;
      align-items: center;

      .subtitle-list-time {
        margin-left: 14px;
        font-size: 14px;
        color: #fff;
      }
      .subtitle-list-info-box {
        position: relative;
        max-width: 85%;
        margin-left: 0.01rem;

        .subtitle-avatar {
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 5px;
          margin: 0 25px 0 5px;
          position: relative;
          img {
            position: absolute;
            border-radius: 50%;
          }
        }

        .subtitle-list-info {
          display: flex;
          padding: 0 0.2rem 0 0.1rem;
          min-height: 30px;
          background: rgba(0, 0, 0, 0.3);
          border-radius: 15px;

          .subtitle-list-content {
            color: #fff;
            font-size: 0.14rem;
            padding: 0.08rem 0;
            .subtitle-list-name {
              height: 0.14rem;
              color: #12c4c4;
            }
          }
        }
      }
    }
  }
}
