
::v-deep .el-input--suffix .el-input__inner {
  background: #080a34;
  border-color: #080a34;
  color: #fff;
}
.visualization-discount-coupon-container {
  height: 100%;
  width: 100%;
  background: rgba(12, 14, 63, 1);
  border: 0.01rem solid rgba(12, 14, 63, 1);
  box-shadow: 0px 0px 40px 0px rgba(12, 21, 48, 0.3);
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  position: relative;

  .discount-main-button {
    display: flex;
    justify-content: space-between;
    margin: 10px 0 0 25px;
    height: 50px;
    width: 500px;
    border-radius: 15px;

    .discount-left {
      display: flex;
      flex: 1;
      overflow-y: hidden;
      margin-right: 20px;
    }
    .discount-button {
      display: flex;
      justify-content: center;
      align-items: center;
      background: #05072a;
      width: 160px;
      height: 28px;
      border-radius: 14px;
      .button-common {
        border: none;
        color: white;
        background: transparent;
        width: 80px;
        height: 28px;
        border-radius: 15px;
        cursor: pointer;
        margin-right: 5px;
      }
      .active {
        background: #4b45ff;
      }
    }
    .send {
      cursor: pointer;
      position: absolute;
      left: 94%;
    }
  }
  .discount-main-info {
    display: flex;
    justify-content: space-between;
    .coupon-bag-title,
    .coupon-bag-detail {
      .title-common {
        color: #fff;
        .el-select {
          margin: 0 !important;
        }
      }
    }
  }
}
