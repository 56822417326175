
.data-center-info-common {
  height: 26px;
  line-height: 26px;
  font-size: 14px;
}
.visualization-screen-data-container {
  padding: 0 0.23rem 0 0.13rem;
  color: #ffffff;
  height: 100%;
  width: 100%;
  padding: 0.08rem 0 0.2rem 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  .screen-data-title {
    font-size: 0.3rem;
    height: 0.3rem;
    line-height: 0.3rem;
    font-weight: bold;
    text-align: center;
  }
  .screen-data-fullscreen {
    position: absolute;
    right: 0;
    top: 8%;
    width: 80px;
    height: 26px;
    line-height: 26px;
    background: #4b45ff;
    border-radius: 13px;
    text-align: center;
    cursor: pointer;
  }
  .screen-data-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 0.14rem;
    .screen-data-info-left {
      display: flex;
      align-items: center;
      margin-right: 0.24rem;
      .screen-data-avatar {
        width: 0.28rem;
        height: 0.28rem;
        border: 0.01rem solid #ffffff;
        border-radius: 50%;
        margin-right: 0.1rem;
      }
      .screen-data-name {
        font-weight: bold;
        margin-right: 0.1rem;
      }
      .icon-zhibozhong-0 {
        margin-left: 10px;
        width: 80px;
        height: 26px;
        background: #fe3266;
        border-radius: 13px;
        text-align: center;
        line-height: 26px;
        font-size: 0.14rem;
      }
    }
    .screen-data-info-right {
      flex: 1;
      display: flex;
      justify-content: space-between;
    }
  }
}
